/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-pulse-rise,
.la-ball-pulse-rise > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.la-ball-pulse-rise {
    display: block;
    font-size: 0;
    color: #fff;
}

.la-ball-pulse-rise.la-dark {
    color: #333;
}

.la-ball-pulse-rise > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}

.la-ball-pulse-rise {
    width: 70px;
    height: 14px;
}

.la-ball-pulse-rise > div {
    width: 10px;
    height: 10px;
    margin: 2px;
    border-radius: 100%;
    -webkit-animation: ball-pulse-rise-even 1s cubic-bezier(.15, .36, .9, .6) 0s infinite;
       -moz-animation: ball-pulse-rise-even 1s cubic-bezier(.15, .36, .9, .6) 0s infinite;
         -o-animation: ball-pulse-rise-even 1s cubic-bezier(.15, .36, .9, .6) 0s infinite;
            animation: ball-pulse-rise-even 1s cubic-bezier(.15, .36, .9, .6) 0s infinite;
}

.la-ball-pulse-rise > div:nth-child(2n-1) {
    -webkit-animation-name: ball-pulse-rise-odd;
       -moz-animation-name: ball-pulse-rise-odd;
         -o-animation-name: ball-pulse-rise-odd;
            animation-name: ball-pulse-rise-odd;
}

.la-ball-pulse-rise.la-sm {
    width: 34px;
    height: 6px;
}

.la-ball-pulse-rise.la-sm > div {
    width: 4px;
    height: 4px;
    margin: 1px;
}

.la-ball-pulse-rise.la-2x {
    width: 140px;
    height: 28px;
}

.la-ball-pulse-rise.la-2x > div {
    width: 20px;
    height: 20px;
    margin: 4px;
}

.la-ball-pulse-rise.la-3x {
    width: 210px;
    height: 42px;
}

.la-ball-pulse-rise.la-3x > div {
    width: 30px;
    height: 30px;
    margin: 6px;
}

/*
 * Animations
 */
@-webkit-keyframes ball-pulse-rise-even {
    0% {
        opacity: 1;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    25% {
        -webkit-transform: translateY(-200%);
                transform: translateY(-200%);
    }
    50% {
        opacity: .35;
        -webkit-transform: scale(.3);
                transform: scale(.3);
    }
    75% {
        -webkit-transform: translateY(200%);
                transform: translateY(200%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -webkit-transform: scale(1);
                transform: translateY(0);
                transform: scale(1);
    }
}
@-moz-keyframes ball-pulse-rise-even {
    0% {
        opacity: 1;
        -moz-transform: scale(1.1);
             transform: scale(1.1);
    }
    25% {
        -moz-transform: translateY(-200%);
             transform: translateY(-200%);
    }
    50% {
        opacity: .35;
        -moz-transform: scale(.3);
             transform: scale(.3);
    }
    75% {
        -moz-transform: translateY(200%);
             transform: translateY(200%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0);
        -moz-transform: scale(1);
             transform: translateY(0);
             transform: scale(1);
    }
}
@-o-keyframes ball-pulse-rise-even {
    0% {
        opacity: 1;
        -o-transform: scale(1.1);
           transform: scale(1.1);
    }
    25% {
        -o-transform: translateY(-200%);
           transform: translateY(-200%);
    }
    50% {
        opacity: .35;
        -o-transform: scale(.3);
           transform: scale(.3);
    }
    75% {
        -o-transform: translateY(200%);
           transform: translateY(200%);
    }
    100% {
        opacity: 1;
        -o-transform: translateY(0);
        -o-transform: scale(1);
           transform: translateY(0);
           transform: scale(1);
    }
}
@keyframes ball-pulse-rise-even {
    0% {
        opacity: 1;
        -webkit-transform: scale(1.1);
           -moz-transform: scale(1.1);
             -o-transform: scale(1.1);
                transform: scale(1.1);
    }
    25% {
        -webkit-transform: translateY(-200%);
           -moz-transform: translateY(-200%);
             -o-transform: translateY(-200%);
                transform: translateY(-200%);
    }
    50% {
        opacity: .35;
        -webkit-transform: scale(.3);
           -moz-transform: scale(.3);
             -o-transform: scale(.3);
                transform: scale(.3);
    }
    75% {
        -webkit-transform: translateY(200%);
           -moz-transform: translateY(200%);
             -o-transform: translateY(200%);
                transform: translateY(200%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -webkit-transform: scale(1);
           -moz-transform: translateY(0);
           -moz-transform: scale(1);
             -o-transform: translateY(0);
             -o-transform: scale(1);
                transform: translateY(0);
                transform: scale(1);
    }
}

@-webkit-keyframes ball-pulse-rise-odd {
    0% {
        opacity: .35;
        -webkit-transform: scale(.4);
                transform: scale(.4);
    }
    25% {
        -webkit-transform: translateY(200%);
                transform: translateY(200%);
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    75% {
        -webkit-transform: translateY(-200%);
                transform: translateY(-200%);
    }
    100% {
        opacity: .35;
        -webkit-transform: translateY(0);
        -webkit-transform: scale(.75);
                transform: translateY(0);
                transform: scale(.75);
    }
}

@-moz-keyframes ball-pulse-rise-odd {
    0% {
        opacity: .35;
        -moz-transform: scale(.4);
             transform: scale(.4);
    }
    25% {
        -moz-transform: translateY(200%);
             transform: translateY(200%);
    }
    50% {
        opacity: 1;
        -moz-transform: scale(1.1);
             transform: scale(1.1);
    }
    75% {
        -moz-transform: translateY(-200%);
             transform: translateY(-200%);
    }
    100% {
        opacity: .35;
        -moz-transform: translateY(0);
        -moz-transform: scale(.75);
             transform: translateY(0);
             transform: scale(.75);
    }
}

@-o-keyframes ball-pulse-rise-odd {
    0% {
        opacity: .35;
        -o-transform: scale(.4);
           transform: scale(.4);
    }
    25% {
        -o-transform: translateY(200%);
           transform: translateY(200%);
    }
    50% {
        opacity: 1;
        -o-transform: scale(1.1);
           transform: scale(1.1);
    }
    75% {
        -o-transform: translateY(-200%);
           transform: translateY(-200%);
    }
    100% {
        opacity: .35;
        -o-transform: translateY(0);
        -o-transform: scale(.75);
           transform: translateY(0);
           transform: scale(.75);
    }
}

@keyframes ball-pulse-rise-odd {
    0% {
        opacity: .35;
        -webkit-transform: scale(.4);
           -moz-transform: scale(.4);
             -o-transform: scale(.4);
                transform: scale(.4);
    }
    25% {
        -webkit-transform: translateY(200%);
           -moz-transform: translateY(200%);
             -o-transform: translateY(200%);
                transform: translateY(200%);
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.1);
           -moz-transform: scale(1.1);
             -o-transform: scale(1.1);
                transform: scale(1.1);
    }
    75% {
        -webkit-transform: translateY(-200%);
           -moz-transform: translateY(-200%);
             -o-transform: translateY(-200%);
                transform: translateY(-200%);
    }
    100% {
        opacity: .35;
        -webkit-transform: translateY(0);
        -webkit-transform: scale(.75);
           -moz-transform: translateY(0);
           -moz-transform: scale(.75);
             -o-transform: translateY(0);
             -o-transform: scale(.75);
                transform: translateY(0);
                transform: scale(.75);
    }
}
