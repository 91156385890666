//
// Keenicons
//

.ki-duotone,
.ki-outline,
.ki-solid {
	line-height: 1;
    font-size: 1rem;
	color: #76d87d;
}

.menu-link:hover .ki-duotone,
.menu-link:hover .ki-outline,
.menu-link:hover .ki-solid,
.menu-link.active .ki-duotone,
.menu-link.active .ki-outline,
.menu-link.active .ki-solid {
  color: #1d4a20;
}

