//
// Buttons Base
//

// Button
.btn {
	--#{$prefix}btn-color: var(--#{$prefix}body-color);

	// Reset outline
	outline: none !important;	

	// Reset focus shadow
	&:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(.shadow-xs) {
		box-shadow: none;
	}

	// Link
	&.btn-link {
		border: 0;
		border-radius: 0;
		padding-left: 0 !important;
		padding-right: 0 !important;
		text-decoration: none;
		font-weight: $btn-font-weight;
	}
 
	// Outline
	&.btn-outline:not(.btn-outline-dashed) {
		border: 1px solid $input-border-color;
	}

	// Outline dashed
	&.btn-outline-dashed {
		border: 1px dashed $input-border-color;
	}

	// Flush
	&.btn-flush {
		@include button-reset();
	}

	// Flex
	&.btn-flex {		
		display: inline-flex;
		align-items: center;
	}

	// Align start
	&.btn-trim-start {
		justify-content: flex-start !important;
		padding-left: 0 !important;		
	}

	// Align start
	&.btn-trim-end {
		justify-content: flex-end !important;
		padding-right: 0 !important;	
	} 
}

// Reset
.btn-reset {
	background-color: transparent;
	border: 0;
	box-shadow: none;
	user-select: none;
	outline: none;
}

// Icons
.btn {
	// Font icon
	> i {
		display: inline-flex;
		font-size: $font-size-base;
		padding-right: 0.35rem;
		vertical-align: middle;
		//line-height: 0;
	}

	// Svg icon
	.svg-icon {
		flex-shrink: 0;
		line-height: 0;
		margin-right: 0.5rem;
	}

	// Icon only button
	&.btn-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		height: $input-height;
		width: $input-height;

		// Remove border
		&:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
			border: 0;
		}

		// Sizes
		&.btn-sm {
			height: $input-height-sm;
			width: $input-height-sm;
			 
		}

		&.btn-lg {
			height: $input-height-lg;
			width: $input-height-lg;
		}

		&.btn-circle {
			border-radius: 50%;
		}

		i,
		.svg-icon {
			padding: 0;
			margin: 0;
			line-height: 1;
		}
	}
}
